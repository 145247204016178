.bg-logo::after{
    content : "";
    display: block;
    position: fixed;
    background-repeat: no-repeat;
    top: 0;
    margin-top: 100px;
    background-image: url('../../../assets/images/emdrLogo.png');
    width: 100%;
    height: 100%;
    opacity : 0.02;
    z-index: -1;
}
#cameraDiv{
  /* position: absolute; */
  z-index: 50;
  text-align: center;
}

#cameraDivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
.prejoin-camera{
  margin: auto;
  margin-top: 20px;
  width: 50%;
  border-radius: 1em;
}

@media (max-width: 768px) {
  .prejoin-camera{
    margin: auto;
    margin-top: 20px;
    width: 90%;
  }
}